import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

import styles from '../pages/contentPage.module.scss';

import { buildLink } from './KhatmaContainer';

import PageContainer from '@/components/PageContainer';
import { Part } from '@/graphql/generated';

type Props = {
  parts: Part[];
};

const MyReservedPartsContainer = (props: Props) => {
  const { t } = useTranslation('common');
  const { parts } = props;

  const renderItem = (part: Part) => {
    if (!part.khatma || !part.khatma.type) {
      return null;
    }
    const link = `${buildLink({
      khatmaId: part.khatmaId,
      khatmaType: part.khatma?.type,
      partId: part.partId,
    })}?khatmaId=${part.khatmaId}`;

    return (
      <li key={`${part.khatmaId}:${part.partId}`}>
        <Link href={link}>
          {part.khatma.type === 'SURA'
            ? `${t('common:surah')} ${t(`common:sura.${parseInt(part.partId, 10)}`, {
                number: parseInt(part.partId, 10),
              })}`
            : t(`common:continue-reading.${part.khatma.type}`, {
                number: parseInt(part.partId, 10),
                name: part.khatma?.name,
              })}
        </Link>
      </li>
    );
  };

  return (
    <div>
      <PageContainer>
        <div className={styles.contentPage}>
          <h2>{t('my-reserved-parts')}</h2>

          {parts && <ul>{parts.map((part) => renderItem(part))}</ul>}
        </div>
      </PageContainer>
    </div>
  );
};

export default MyReservedPartsContainer;
