import useTranslation from 'next-translate/useTranslation';

import styles from '../pages/contentPage.module.scss';

import PageContainer from '@/components/PageContainer';
import Link from '@/dls/Link/Link';
import { Khatma } from '@/graphql/generated';

type Props = {
  khatmas: Khatma[];
};

const KhatmasContainer = (props: Props) => {
  const { t } = useTranslation('common');
  const { khatmas } = props;

  return (
    <div>
      <PageContainer>
        <div className={styles.contentPage}>
          <h2>{t('open-khatmas')}</h2>
          {khatmas && (
            <ul>
              {khatmas.map((khatma) => (
                <li key={khatma.khatmaId}>
                  <Link href={`/khatma/${khatma.khatmaId}`}>{khatma.name}</Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </PageContainer>
    </div>
  );
};

export default KhatmasContainer;
