/* eslint-disable react/no-multi-comp */

import { useQuery } from '@apollo/client';
import { NextPage } from 'next';

import styles from './index.module.scss';

import PageContainer from '@/components/PageContainer';
import KhatmasContainer from '@/containers/KhatmasContainer';
import MyReservedPartsContainer from '@/containers/MyReservedPartsContainer';
import Spinner from '@/dls/Spinner/Spinner';
import { HomeDocument } from '@/graphql/generated';
import useRequireAuth from '@/hooks/auth/useRequireAuth';

const Index: NextPage = (): JSX.Element => {
  useRequireAuth();
  const { data, loading } = useQuery(HomeDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        status: 'IN_PROGRESS',
      },
    },
  });

  // const { t, lang } = useTranslation('home');
  return (
    <>
      {/* <Head>
        <link rel="preload" as="image" href="/images/background.jpg" crossOrigin="anonymous" />
      </Head> */}
      {/* <NextSeoWrapper
        title={t('home:noble-quran')}
        url={getCanonicalUrl(lang, '')}
        languageAlternates={getLanguageAlternates('')}
      /> */}
      <div className={styles.pageContainer}>
        <div className={styles.flow}>
          <PageContainer>
            {loading && <Spinner />}
            {data?.myParts?.items?.length > 0 && (
              <MyReservedPartsContainer parts={data?.myParts?.items} />
            )}
            {data?.khatmas?.items?.length > 0 && <KhatmasContainer khatmas={data?.khatmas.items} />}
          </PageContainer>
          {/* <HomePageHero /> */}
          {/* <div className={classNames(styles.flowItem, styles.fullWidth)}>
            <ReadingStreak />
          </div>
          <div className={classNames(styles.flowItem, styles.fullWidth)}>
            <RecentReadingSessions />
          </div>
          <div className={classNames(styles.flowItem, styles.fullWidth)}>
            <BookmarksAndCollectionsSection />
          </div> */}
          {/* <div className={styles.flowItem}>
            <ChapterAndJuzListWrapper chapters={chapters} />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Index;
